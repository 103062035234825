import axios from "axios";
import * as actions from "../fileDownloaderAction";
import {baseUrl, createAxiosInterceptor} from "./api";

const fileDownloader =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type != actions.fileDownloaderBegan) return next(action);

    const { url, data, onStart, onSuccess, onError, params } = action.payload;

    if (onStart) dispatch({ type: onStart });

    next(action);
    createAxiosInterceptor(dispatch);
    try {
      const response = await axios.request({
        baseURL: baseUrl,
        url,
        responseType: "blob",
        headers: {
          Authorization:
            typeof getState().admin.currentAdmin.data.accessToken == "undefined"
              ? ""
              : getState().admin.currentAdmin.data.accessToken,
          "Accept-Language": "ar",
        },
        data,
        params,
      });

      if (onSuccess) {
        dispatch({
          type: onSuccess,
          payload: response.data,
        });
      }
    } catch (error) {
      if (onError)
        dispatch({
          type: onError,
          payload: error.response.data,
        });
    }
  };

export default fileDownloader;
