import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { loadCourses, addVideo } from "../../store/resources";
import VideoCell from "./videoCell";
import { useForm } from "react-hook-form";
import { inputDecorator } from "../../utils/styleHelper";

import PrimaryButton from "../buttons/primaryButton";
import LoadingIndicator from "../Loaders/loadingIndicator";

const VideosListComponent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.admin.resources);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch(loadCourses());
  }, []);
  const onSubmit = (data) => {
    dispatch(addVideo(id, data));
  };

  if (courses.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="flex flex-col mt-6 space-y-4">
        <div className="flex flex-row">
          <p className="w-1/2">
            {Object.keys(courses.data).length > 0 ? courses.data[id].title : ""}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col bg-secondary p-4 space-y-4 w-5/6">
            <div className="flex flex-row ">
              <input
                className={
                  typeof errors.title == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="عنوان الدرس"
                {...register("title", { required: true })}
              />
              <input
                className={
                  typeof errors.videoUrl == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="رابط الفيديو"
                {...register("videoUrl", { required: true })}
              />
            </div>
            <textarea
              rows={5}
              {...register("description", { required: true })}
              placeholder="أكتب ماتود أن يراه متابعوك"
              className={
                typeof errors.description == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
            />
            <div className="w-1/6 my-2">
              <PrimaryButton title="إضافة" isLoading={false} />
            </div>
          </div>
        </form>

        {Object.keys(courses.data).length > 0 ? (
          <div className="">
            <p className="mt-8">الفيديوهات الموجودة:</p>
            {courses.data[id].lessons.map((v, i) => {
              return <VideoCell item={v} key={i} courseId={id} />;
            })}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
};

export default VideosListComponent;
