import React from "react";
import { Link } from "react-router-dom";

const CourseCell = (props) => {
  return (
    <div className="flex flex-row bg-secondary p-4 w-1/3 justify-between my-4 hover:bg-hover-color">
      <div className="w-full">
        <p>{props.item.title}</p>
      </div>
      <Link to={"/admin/videosSection/videoList/" + props.item.id}>
        <u className="text-primary-blue-color">تحرير</u>
      </Link>
    </div>
  );
};

export default CourseCell;
