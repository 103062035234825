import React, { Component } from "react";
import "./App.css";
import routes from "./routes";
import configureStore, { history } from "./store/configureStore";
import { Provider } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import ProtectedRoute from "./utils/ProtectedRoute";

const store = configureStore();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              {routes.map((route, idx) => {
                if (route.isSecure) {
                  return (
                    <ProtectedRoute
                      path={route.path}
                      component={route.component}
                      key={idx}
                    />
                  );
                } else {
                  return (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={idx}
                    />
                  );
                }
              })}
            </Switch>
          </ConnectedRouter>
        </Provider>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
