import React, { useEffect } from "react";
import OutlineButton from "../../buttons/outlineButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMembersList } from "../../../store/members";
import MemberListCell from "./membersListCell";
import { useSelector } from "react-redux";
import LoadingIndicator from "../../Loaders/loadingIndicator";

const MembersListsComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const members = useSelector((state) => state.admin.members);

  useEffect(() => {
    dispatch(getMembersList());
  }, []);
  if (members.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="flex flex-col mt-6">
        <div className="flex flex-row w-full justify-between">
          <p>قوائم الأعضاء</p>
          <OutlineButton
            title="إضافة عضو جديد"
            action={() => history.push("/admin/membersList/addMember")}
            isLoading={false}
          />
        </div>
        <div className="w-1/4 space-y-3">
          {Object.keys(members.data).length > 0 ? (
            <div>
              {Object.keys(members.data).map((v, i) => {
                console.log(v);
                return <MemberListCell key={i} title={v} />;
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
};

export default MembersListsComponent;
