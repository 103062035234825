import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory, useParams } from "react-router-dom";
import ClassesComponent from "../components/classes/classesComponent";
import EmailComponent from "../components/admin/emailComponent";
import HomeForm from "../components/admin/HomeForm";
import memberDetailsComponent from "../components/admin/members/memberDetailsComponent";
import membersComponent from "../components/admin/members/membersComponent";
import membersListsComponent from "../components/admin/members/membersListsComponent";
import VideosListComponent from "../components/video/videoListComponent";
import VideosSectionComponent from "../components/video/videosSectionComponent";
import { logout, refreshTokenAction } from "../store/auth";
import { notSelectedCell, selectedCell } from "../utils/styleHelper";
import ResourcesComponent from "../components/resources/resourcesComponents";
import AddMember from "../components/admin/members/addMember";

export default function Admin() {
  const dispatch = useDispatch();
  const { subPage } = useParams();

  useEffect(() => {
    dispatch(refreshTokenAction());
  }, []);
  const auth = useSelector((state) => state.admin.currentAdmin);
  const history = useHistory();

  if (
    typeof auth.data.accessToken !== "undefined" &&
    typeof auth.data.userType !== "undefined" &&
    auth.data.userType === "Admin"
  ) {
    return (
      <div className="relative flex min-h-screen">
        <div className="bg-secondary w-64 text-white px-2 py-5 space-y-6">
          <img
            className="p-4 w-24 h-24 "
            src="../../assets/images/hrrir.png"
            alt=""
          />
          <nav>
            <Link to="/admin/home">
              <p className={subPage == "home" ? selectedCell : notSelectedCell}>
                <i className="fa fa-home me-1" aria-hidden="true"></i>الصفحة
                الرئيسية
              </p>
            </Link>

            <Link to="/admin/email">
              <p
                className={subPage == "email" ? selectedCell : notSelectedCell}
              >
                <i className="fa fa-envelope me-1" aria-hidden="true"></i>البريد
                الإلكتروني
              </p>
            </Link>
            <Link to="/admin/membersList">
              <p
                className={
                  subPage == "membersList" ? selectedCell : notSelectedCell
                }
              >
                <i className="fa fa-users me-1" aria-hidden="true"></i>قائمة
                الأعضاء
              </p>
            </Link>
            <Link to="/admin/classes">
              <p
                className={
                  subPage == "classes" ? selectedCell : notSelectedCell
                }
              >
                <i className="fa fa-graduation-cap me-1" aria-hidden="true"></i>
                الكلاسات
              </p>
            </Link>
            <Link to="/admin/resources">
              <p
                className={
                  subPage == "resources" ? selectedCell : notSelectedCell
                }
              >
                <i className="fa fa-list-alt me-1" aria-hidden="true"></i>
                المصادر
              </p>
            </Link>
            <Link to="/admin/videosSection">
              <p
                className={
                  subPage == "videosSection" ? selectedCell : notSelectedCell
                }
              >
                <i className="fa fa-video-camera me-1" aria-hidden="true"></i>
                الفيديوهات
              </p>
            </Link>
            <a
              href="https://analytics.google.com/analytics/web"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="block px-4 py-4 hover:bg-hover-color">
                <i className="fa fa-bar-chart me-1" aria-hidden="true"></i>
                الإحصائيات
              </p>
            </a>

            <a
              onClick={() => {
                dispatch(logout());
                history.push("/");
              }}
              className="block px-4 py-4 hover:bg-hover-color"
            >
              <i className="fa fa-sign-out me-1" aria-hidden="true"></i>إطلع
            </a>
          </nav>
        </div>
        <div className="p-10 flex-1 bg-primary text-white">
          <Switch>
            <Route exact path="/admin/home" component={HomeForm} />
            <Route exact path="/admin/email" component={EmailComponent} />
            <Route
              exact
              path="/admin/membersList"
              component={membersListsComponent}
            />
            <Route
              exact
              path="/admin/membersList/addMember"
              component={AddMember}
            />
            <Route
              exact
              path="/admin/membersList/:key"
              component={membersComponent}
            />
            <Route
              exact
              path="/admin/membersList/:key/:mId"
              component={memberDetailsComponent}
            />
            <Route exact path="/admin/classes" component={ClassesComponent} />
            <Route
              exact
              path="/admin/videosSection"
              component={VideosSectionComponent}
            />
            <Route
              exact
              path="/admin/videosSection/videoList/:id"
              component={VideosListComponent}
            />
            <Route
              exact
              path="/admin/resources"
              component={ResourcesComponent}
            />
          </Switch>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
