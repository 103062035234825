import React from "react";
import { Link, useParams } from "react-router-dom";

const NameCellComponent = (props) => {
  const { key } = useParams();
  return (
    <div className="flex flex-row bg-secondary justify-between p-4 my-4">
      <p className="w-1/4">{props.name}</p>
      <p className="w-1/4">{props.email}</p>
      <p className="w-1/4 text-center">{props.country}</p>
      <Link to={"/admin/membersList/" + key + "/" + props.id}>
        <u className="text-primary-blue-color w-1/4">التفاصيل</u>
      </Link>
    </div>
  );
};

export default NameCellComponent;
