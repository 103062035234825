import React from "react";
import LoginBox from "../components/auth/loginBox";

export default function Login() {
  return (
    <div className="  bg-primary h-screen bg-fixed overflow-hidden">
      <div className="flex flex-row w-full justify-center md:h-full md:items-center">
        <LoginBox />
      </div>
    </div>
  );
}
