import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateVideo } from "../../store/resources";
import PrimaryButton from "../buttons/primaryButton";
import ErrorAlert from "../Loaders/errorAlert";

const VideoCell = (props) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(props.item.title);
  const [url, setUrl] = useState(props.item.video);
  const [description, setDescription] = useState(props.item.description);
  const [error, setError] = useState("");

  const onSubmit = () => {
    if (title == "" || url == "" || description == "")
      setError("لازم كل الحقول تكون مليانة");
    else {
      setError("");
      dispatch(
        updateVideo(props.courseId, props.item.id, {
          title: title,
          description: description,
          videoUrl: url,
        })
      );
    }
  };
  return (
    <div className="flex flex-col bg-secondary p-4 space-y-4 w-5/6 my-4">
      <div className="flex flex-row ">
        <input
          className="bg-custom-g  rounded-sm w-2/3 me-4"
          type="text"
          onChange={(v) => setTitle(v.target.value)}
          value={title}
          placeholder="عنوان الدرس"
        />
        <input
          className="bg-custom-g rounded-sm w-1/3"
          type="text"
          onChange={(v) => setUrl(v.target.value)}
          value={url}
          placeholder="رابط الفيديو"
        />
      </div>
      <textarea
        rows={5}
        placeholder="أكتب ماتود أن يراه متابعوك"
        className="bg-custom-g rounded-sm"
        value={description}
        onChange={(v) => setDescription(v.target.value)}
      />
      <div className="flex flex-row w-full my-2 justify-between items-center">
        <div className="w-1/6">
          <PrimaryButton title="تحديث" isLoading={false} action={onSubmit} />
        </div>
        <div className="w-3/6">
          {error == "" ? <div></div> : <ErrorAlert message={error} />}
        </div>
      </div>
    </div>
  );
};

export default VideoCell;
