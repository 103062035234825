import { inputDecorator } from "../../utils/styleHelper";
import { useForm } from "react-hook-form";
import PrimaryButton from "../buttons/primaryButton";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import MultiSelect from "../selection/multiSelection";
import { useSelector } from "react-redux";
import { addResource, getResources } from "../../store/resources";
import { getClasses } from "../../store/classes";
import LoadingIndicator from "../Loaders/loadingIndicator";
import ResourceCell from "./resourceCell";

const ResourcesComponent = () => {
  const [imageUrl, setImageUrl] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClasses());
    dispatch(getResources());
  }, []);
  const classes = useSelector((state) => state.admin.classes);
  const resources = useSelector((state) => state.admin.resources);

  const maxLength = 250;
  const [selectedItems, setSelected] = useState([]);
  const [counter, setCounter] = useState(0);

  const getSelected = (items) => {
    setSelected(items);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      addResource({
        imageUrl: data.imageUrl,
        description: data.description,
        title: data.title,
        classIds: selectedItems.map((v) => v.id),
        url: data.url.length == 0 ? null : data.url,
      })
    );
  };
  if (classes.loading || resources.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="flex flex-col mt-6 space-y-4 align-middle">
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>إضافة مصدر جديد:</p>
          <div className="flex flex-row bg-secondary p-4 w-4/5 mb-8 ">
            <div className="flex flex-col w-2/3 ">
              <div></div>
              <input
                className={
                  typeof errors.title == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="عنوان المصدر"
                {...register("title", { required: true })}
              />
              <textarea
                placeholder="نبذة عامة"
                maxLength={maxLength}
                className="bg-custom-g my-4"
                onChangeCapture={(v) => setCounter(v.target.value.length)}
                {...register("description", { required: true })}
              />

              <div>
                ({counter}/{maxLength})
              </div>
              <div className="flex flex-row w-full justify-between items-center">
                <div className="w-1/3">
                  {" "}
                  <PrimaryButton
                    title="إضافة"
                    isLoading={false}
                    action={() => console.log(selectedItems)}
                  />
                </div>
                <div className="w-2/4">
                  <input
                    className={
                      typeof errors.title == "undefined"
                        ? inputDecorator(false)
                        : inputDecorator(true)
                    }
                    type="text"
                    placeholder="رابط URL خارجي  "
                    {...register("url")}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/3 mx-2">
              <input
                className={
                  typeof errors.title == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                onInput={(v) => {
                  setImageUrl(v.target.value);
                }}
                placeholder="رابط الصورة"
                {...register("imageUrl", { required: true })}
              />

              {imageUrl == "" ? (
                <div className="w-32 h-24 mb-1"></div>
              ) : (
                <img src={imageUrl} className="w-32 h-20 mx-2 mt-2 mb-3" />
              )}
              <MultiSelect
                items={classes.data}
                getSelectedItems={getSelected}
                selected={[]}
              />
            </div>
          </div>
        </form>

        {resources.data.length > 0 ? (
          <div>
            <p className="mb-2 mt-4">المصادر الحالية:</p>

            {resources.data.map((v, i) => {
              console.log(v);
              return (
                <ResourceCell item={v} key={i} classesList={classes.data} />
              );
            })}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
};

export default ResourcesComponent;
