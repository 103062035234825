import React from "react";

const PrimaryButton = (props) => {
  if (props.isLoading) {
    return (
      <button
        className="animate-pulse bg-focus-button rounded-md px-4 py-2 text-white w-full"
        disabled
      >
        {props.title}
      </button>
    );
  } else {
    return (
      <button
        className="transition duration-300 ease-in-out transform rounded-md bg-primary-blue-color focus:bg-focus-button hover:bg-hover-button text-white px-4 py-2 w-full"
        onClick={props.action}
      >
        {props.title}
      </button>
    );
  }
};

export default PrimaryButton;
