import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { downloadExcel, getMembersList } from "../../../store/members";
import OutlineButton from "../../buttons/outlineButton";
import LoadingIndicator from "../../Loaders/loadingIndicator";
import NameCellComponent from "./nameCellComponent";

const MembersComponent = () => {
  const { key } = useParams();
  const members = useSelector((state) => state.admin.members);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMembersList());
  }, []);
  if (members.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="flex flex-col space-y-4 mt-6">
        <div className="flex flex-row w-full justify-between">
          <p>{"المسجلين في قائمة " + " " + key}</p>
          {Object.keys(members.data).length > 0 ? (
            <p>{"عدد المسجلين: " + " " + members.data[key].length} </p>
          ) : (
            <p></p>
          )}
          <OutlineButton
            title="تحميل القائمة"
            action={() => dispatch(downloadExcel(key))}
            isLoading={members.loading}
          />
        </div>
        <div className="w-full space-y-3">
          {Object.keys(members.data).length > 0 ? (
            <div>
              {members.data[key].length > 0 ? (
                members.data[key].map((v, i) => {
                  return (
                    <NameCellComponent
                      params={key}
                      name={v.fullName}
                      email={v.email}
                      country={v.nationality}
                      id={v.id}
                      key={i}
                    />
                  );
                })
              ) : (
                <div>لايوجد أعضاء مسجلين بعد (T＿T)</div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
};

export default MembersComponent;
