import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getClasses } from "../../store/classes";
import { inputDecorator } from "../../utils/styleHelper";
import PrimaryButton from "../buttons/primaryButton";
import Coupon from "./couponComponent";
import LoadingIndicator from "../Loaders/loadingIndicator";
import SuccessAlert from "../Loaders/successAlert";

import { useSelector } from "react-redux";
import {
  addCoupon,
  getCoupon,
  getHomeConfig,
  setDefaultClass,
  setHomeConfig,
} from "../../store/home";
import moment from "moment";

const HomeForm = () => {
  useEffect(() => {
    dispatch(getClasses());
    dispatch(getHomeConfig());
    dispatch(getCoupon());
  }, []);
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const classes = useSelector((state) => state.admin.classes);
  const home = useSelector((state) => state.admin.home);

  const [code, setCode] = useState("");
  const [percent, setPercent] = useState("");
  const [error, setError] = useState("");

  const [currentClass, setCurrentClass] = useState();

  useEffect(() => {
    setValue(
      "firstAd",
      home.data.promotionalVideos === undefined
        ? ""
        : home.data.promotionalVideos[0]
    );
    setValue(
      "secondAd",
      home.data.promotionalVideos === undefined
        ? ""
        : home.data.promotionalVideos[1]
    );
    setValue("header", home.data.header === undefined ? "" : home.data.header);
    setValue(
      "description",
      home.data.description === undefined ? "" : home.data.description
    );
    // setValue(
    //   "promotionalMessage",
    //   home.data.promotionalMessage === undefined
    //     ? ""
    //     : home.data.promotionalMessage
    // );
    // setValue(
    //   "classStartDate",
    //   home.data.classStartDate === undefined
    //     ? ""
    //     : getTimeWithoutHours(home.data.classStartDate)
    // );

    // setValue(
    //   "classEndDate",
    //   home.data.classEndDate === undefined
    //     ? ""
    //     : getTimeWithoutHours(home.data.classEndDate)
    // );
    setValue(
      "isOpen",
      home.data.isRegistrationOpen === undefined
        ? ""
        : home.data.isRegistrationOpen
        ? "open"
        : "close"
    );
    setValue(
      "isShow",
      home.data.isCoachInfoVisible === undefined
        ? ""
        : home.data.isCoachInfoVisible
        ? "show"
        : "hide"
    );
  }, [home.data]);

  // const getTimeWithoutHours = (date) => {
  //   const d = new Date(date);
  //   const m = moment(d);
  //   m.subtract(3, "hours");
  //   console.log(m.format("yyyy-MM-DDThh:mm:ss"));
  //   return m.format("yyyy-MM-DDThh:mm:ss");
  // };
  const onSubmit = (data) => {
    dispatch(
      setHomeConfig({
        isRegistrationOpen: data.isOpen === "open",
        isCoachInfoVisible: data.isShow === "show",
        promotionalVideos: [data.firstAd, data.secondAd],
        header: data.header,
        description: data.description,
        // promotionalMessage: data.promotionalMessage,
        // classStartDate: data.classStartDate,
        // classEndDate: data.classEndDate,
      })
    );
  };

  if (classes.loading || home.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-4 w-2/3 space-y-3 mb-16">
          <p>الكلاس الحالي:</p>
          <div className="w-full  flex flex-row items-center ">
            <div className="w-1/3 me-4">
              {classes.data.length > 0 ? (
                <select
                  className={inputDecorator(false)}
                  onChange={(v) => setCurrentClass(v.target.value)}
                >
                  {classes.data.map((v, i) => {
                    return (
                      <option value={v.id} key={i}>
                        {v.title}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <div></div>
              )}
            </div>
            <div className="w-1/3 me-4">
              <PrimaryButton
                title="احفظ الكلاس"
                action={(e) => {
                  e.preventDefault();
                  dispatch(
                    setDefaultClass({
                      classId: currentClass,
                    })
                  );
                }}
              />
            </div>
            {home.SuccessMessage === "" ? (
              <div></div>
            ) : (
              <div className="w-1/3">
                <SuccessAlert message={home.SuccessMessage} />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col ">
          <p>حالة التسجيل:</p>

          <div className="flex flex-row mt-2 mb-1 items-center">
            <div className="w-1/3">
              <select
                {...register("isOpen")}
                className={inputDecorator(false)}
                defaultValue={home.data.isRegistrationOpen}
              >
                <option value="open">الإنضمام </option>{" "}
                <option value="close">قائمة الانتظار </option>
              </select>
            </div>
          </div>
          <div className="my-6">
            <p>معلومات المدرب:</p>

            <div className="w-1/3">
              <select {...register("isShow")} className={inputDecorator(false)}>
                <option value="show">إظهار </option>{" "}
                <option value="hide">إخفاء</option>
              </select>
            </div>
          </div>

          <div className="flex flex-row  items-center ">
            <div className="flex flex-col space-y-3 me-4">
              <label>الفيديو الإعلاني(1):</label>
              <input
                className={
                  typeof errors.firstAd == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="ادخل رابط الفيديو الإعلاني"
                {...register("firstAd", { required: true })}
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label>الفيديو الإعلاني(2):</label>
              <input
                className="bg-custom-g py-2  text-white rounded-sm w-full "
                type="text"
                placeholder="ادخل رابط الفيديو الإعلاني"
                {...register("secondAd")}
              />
            </div>
          </div>
          <div className="flex flex-col my-4 w-2/3 space-y-3">
            <label>الهيدر:</label>
            <textarea
              rows="4"
              placeholder="أكتب ماتود أن يراه متابعوك"
              className={
                typeof errors.header == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              {...register("header", { required: true })}
            />
          </div>
          <div className="flex flex-col my-4 w-2/3 space-y-3">
            <label>الوصف:</label>
            <textarea
              rows="4"
              placeholder="أكتب مزيد من التفاصيل هنا"
              className={
                typeof errors.description == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              {...register("description", { required: true })}
            />
          </div>
          {/* <div className="flex flex-col my-4 w-2/3 space-y-3">
            <label>ملاحظة التوقيت:</label>
            <textarea
              rows="4"
              placeholder="ادخل ملاحظة التوقيت"
              className={
                typeof errors.promotionalMessage == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              {...register("promotionalMessage", { required: true })}
            />
          </div> */}
          {/* <div className="flex flex-row  items-center ">
            <div className="flex flex-col space-y-3 me-4">
              <label>تاريخ بداية المعسكر:</label>
              <input
                className={
                  typeof errors.classStartDate == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="datetime-local"
                placeholder="ادخل تاريخ البداية "
                {...register("classStartDate", { required: true })}
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label>تاريخ نهاية المعسكر:</label>

              <input
                className={
                  typeof errors.classEndDate == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="datetime-local"
                placeholder="ادخل تاريخ النهاية"
                {...register(
                  "classEndDate",
                  { required: true },
                  { valueAsDate: true }
                )}
              />
            </div>
          </div> */}
          <div className="w-1/6 my-8">
            <PrimaryButton title="حفظ" isLoading={false} />
            {home.errorMessage !== "" ? (
              <p className="text-red-600 text-center font-bold">
                {home.errorMessage.detail || home.errorMessage.title}
              </p>
            ) : (
              <div></div>
            )}
          </div>
          {home.SuccessMessageConfig === "" ? (
            <div></div>
          ) : (
            <SuccessAlert message={home.SuccessMessageConfig} />
          )}
          <div className="flex flex-col my-4 w-1/3 space-y-3">
            <label>الرموز الترويجية:</label>
            {home.coupons.length > 0 ? (
              <div>
                {home.coupons.map((v, i) => {
                  return (
                    <Coupon
                      code={v.code}
                      discount={v.discountPercentage}
                      id={v.id}
                      key={i}
                    />
                  );
                })}
              </div>
            ) : (
              <div></div>
            )}{" "}
            <div className="flex flex-row bg-secondary justify-between w-full p-2">
              <input
                className="w-1/4 bg-custom-g rounded-sm"
                placeholder="الرمز"
                onChange={(v) => setCode(v.target.value)}
              />
              <input
                className="w-1/4 bg-custom-g rounded-sm"
                placeholder="الخصم"
                onChange={(v) => setPercent(v.target.value)}
              />
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();

                  if (code.length == 0 || percent.length == 0) {
                    setError("عبي كلش");
                    return;
                  }
                  if (!/^[0-9]{1,2}$/.test(percent)) {
                    setError(
                      "لازم يكون قيمة الخصم بين 0 و 100 يا سبك بتجيب فينا العيد"
                    );
                    return;
                  }

                  setError("");

                  dispatch(
                    addCoupon({
                      code: code,
                      discountPercentage: percent / 100,
                    })
                  );
                }}
              >
                <u className="text-blue-700">إضافة</u>
              </a>
            </div>
            {error.length > 0 ? (
              <p className="text-red-600 text-center font-bold">{error}</p>
            ) : (
              <div></div>
            )}
            {home.errorMessageCoupon !== "" ? (
              <p className="text-red-600 text-center font-bold">
                {home.errorMessageCoupon.detail}
              </p>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </form>
    );
  }
};

export default HomeForm;
