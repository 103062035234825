// classes.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "classes",
  initialState: {
    data: {},
    onlyTitleList: [],
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
    SuccessMessage: "",
  },
  reducers: {
    classRequested: (classes, action) => {
      classes.loading = true;
      classes.isSuccess = false;
    },
    classRequestFailed: (classes, action) => {
      classes.loading = false;
      classes.SuccessMessage = "";
      classes.isFailed = true;
      classes.isSuccess = false;
      classes.errorMessage = action.payload;
    },
    classRequestSucceeded: (classes, action) => {
      classes.data = action.payload;
      classes.loading = false;
      classes.errorMessage = "";
      classes.isFailed = false;
      classes.isSuccess = true;
      classes.onlyTitleList = classes.data.map((v) => v.title);
      classes.lastFetch = Date.now();
    },
    classAdded: (classes, action) => {
      classes.loading = false;
      classes.errorMessage = "";
      classes.data.push(action.payload);
    },
    classUpdated: (classes, action) => {
      classes.loading = false;
      classes.errorMessage = "";
      var index = classes.data.findIndex(
        (item) => item.id == action.payload.id
      );
      console.log(index);
      classes.data[index] = action.payload;
    },
  },
});

// Actions
export const {
  classRequested,
  classRequestFailed,
  classRequestSucceeded,
  classAdded,
  classUpdated,
} = slice.actions;

export default slice.reducer;

const classesUrl = "/v1/admin/classes";

export const getClasses = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: classesUrl,
      onStart: classRequested.type,
      onSuccess: classRequestSucceeded.type,
      onError: classRequestSucceeded.type,
      method: "get",
    })
  );
};

export const addClass = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: classesUrl,
      data: {
        title: data.title,
        description: "string",
        image: data.image,
        price: data.price,
      },
      onStart: classRequested.type,
      onSuccess: classAdded.type,
      onError: classRequestSucceeded.type,
      method: "post",
    })
  );
};

export const updateClass = (data, id) => (dispatch, getState) => {
  console.log(data);
  dispatch(
    apiCallBegan({
      url: classesUrl + "/" + id,
      data: data,
      onStart: classRequested.type,
      onSuccess: classUpdated.type,
      onError: classRequestSucceeded.type,
      method: "PATCH",
    })
  );
};
