//members.js

import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";
import { fileDownloaderBegan } from "./fileDownloaderAction";

//Reducer
const slice = createSlice({
  name: "members",
  initialState: {
    data: {},
    memberDetailsData: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
    SuccessMessage: "",
  },
  reducers: {
    memberRequested: (member, action) => {
      member.loading = true;
      member.isSuccess = false;
    },
    memberRequestFailed: (member, action) => {
      member.loading = false;
      member.SuccessMessage = "";
      member.isFailed = true;
      member.isSuccess = false;
      member.errorMessage = action.payload;
    },
    memberRequestSucceeded: (member, action) => {
      member.loading = false;
      member.data = action.payload;
      member.errorMessage = "";
      member.isFailed = false;
      member.isSuccess = true;
      member.lastFetch = Date.now();
    },
    memberAdded: (member, action) => {
      member.loading = false;
      member.errorMessage = "";
      member.isFailed = false;
      member.isSuccess = true;
      member.lastFetch = Date.now();
    },
    memberDetails: (member, action) => {
      member.loading = false;
      member.errorMessage = "";
      member.isFailed = false;
      member.isSuccess = true;
      member.memberDetailsData = action.payload;
      member.lastFetch = Date.now();
    },
    memberDeleted: (member, action) => {
      member.loading = false;
      member.memberDetailsData = {};
    },
    memberListDownloaded: (member, action) => {
      member.loading = false;
      const url = window.URL.createObjectURL(new Blob([action.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
});

//Actions
export const {
  memberRequested,
  memberRequestFailed,
  memberRequestSucceeded,
  memberAdded,
  memberDetails,
  memberDeleted,
  memberListDownloaded,
} = slice.actions;

export default slice.reducer;

const studentUrl = "/v1/admin/students";

export const addMember = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: studentUrl,
      data: data,
      onStart: memberRequested.type,
      onSuccess: memberAdded.type,
      onError: memberRequestFailed.type,
      method: "post",
      path: "/admin/membersList/",
    })
  );
};

export const getMembersList = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: studentUrl,
      onStart: memberRequested.type,
      onSuccess: memberRequestSucceeded.type,
      onError: memberRequestFailed.type,
    })
  );
};

export const getMemberDetails = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: studentUrl + "/" + id,
      onStart: memberRequested.type,
      onSuccess: memberDetails.type,
      onError: memberRequestFailed.type,
    })
  );
};

export const updateMemberDetails = (id, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      data,
      url: studentUrl + "/" + id,
      onStart: memberRequested.type,
      onSuccess: memberDetails.type,
      onError: memberRequestFailed.type,
      method: "patch",
    })
  );
};

export const deleteMember = (id, key) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: studentUrl + "/" + id,
      onStart: memberRequested.type,
      onSuccess: memberDeleted.type,
      onError: memberRequestFailed.type,
      method: "delete",
      path: "/admin/membersList/" + key,
    })
  );
};

const excelUrl = "/v1/admin/students/excel";

export const downloadExcel = (id) => (dispatch, getState) => {
  dispatch(
    fileDownloaderBegan({
      url: excelUrl,
      onStart: memberRequested.type,
      onError: memberRequestFailed.type,
      onSuccess: memberListDownloaded.type,
      params: {
        group: id,
      },
    })
  );
};
