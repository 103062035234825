import React, {useState, useRef, useEffect} from "react";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";
import PrimaryButton from "../buttons/primaryButton";
import LoadingIndicator from "../Loaders/loadingIndicator";
import {useDispatch, useSelector} from "react-redux";
import {inputDecorator} from "../../utils/styleHelper";
import {getClasses} from "../../store/classes";
import {resetState, sendEmail} from "../../store/emailSender";

const EmailComponent = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getClasses());
    dispatch(resetState())
  }, []);

  const onSubmit = (data) =>  {
    data.htmlMessage = content;
    dispatch(sendEmail(data));
  }

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const emailSender = useSelector((state) => state.admin.emailSender)
  const classes = useSelector((state) => state.admin.classes);

  const config = {
    style: {
      backgroundColor: "#FFFFFF",
      color: "black"
    },
    hidePoweredByJodit: true,
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  if (emailSender.loading) {
    return <LoadingIndicator/>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {" "}
      <div className="flex flex-col">
        <p>أرسل رسالة بريد إلكتروني</p>
        <div className="w-full flex flex-col bg-secondary p-6 my-4">
          {emailSender.isFailed && (
              <p className="text-red-600 text-center font-bold">
                {emailSender.errorMessage}
              </p>
          )}
          {emailSender.isSuccess && (
              <p className="text-green-600 text-center font-bold">
                {emailSender.SuccessMessage}
              </p>
          )}
          <label>إرسال إلى:</label>
          <div className="w-1/4 my-3">
            {classes.data.length > 0 ? (
                <select
                    className={inputDecorator(false)}
                    {...register("recipientGroup")}
                >
                  <option value={""} key={-1}>
                    إختر مجموعة
                  </option>
                  {classes.data.map((v, i) => {
                    if (i === classes.data.length - 1) {
                      return (
                          <>
                            <option value={v.id} key={i}>
                              {v.title}
                            </option>
                            <option value={"WaitingList"} key={i + 1}>
                              {"Waiting List"}
                            </option>
                          </>
                      );
                    }
                    return (
                        <option value={v.id} key={i}>
                          {v.title}
                        </option>
                    );
                  })}
                </select>
            ) : (
                <div/>
            )}
          </div>
          <input
            className="bg-custom-g py-2  text-white rounded-sm w-full my-4"
            type="email"
            placeholder="أدخل عنوان البريد الإلكتروني المرسل اليه"
            {...register("recipientEmail")}
          />
          <label>نسخة إلى:</label>
          <div className="w-1/4 my-3">
            {classes.data.length > 0 ? (
                <select
                    className={inputDecorator(false)}
                    {...register("carbonCopyGroup")}
                >
                  <option value={""} key={-1}>
                    إختر مجموعة
                  </option>
                  {classes.data.map((v, i) => {
                    return (
                        <option value={v.id} key={i}>
                          {v.title}
                        </option>
                    );
                  })}
                </select>
            ) : (
                <div/>
            )}
          </div>
          <input
            className="bg-custom-g py-2  text-white rounded-sm w-full mt-4"
            type="email"
            placeholder="أدخل عنوان البريد الإلكتروني المرسل اليه"
            {...register("carbonCopyEmail")}
          />
          <label className="mt-4">عنوان الرسالة:</label>
          <input
            className="bg-custom-g py-2  text-white rounded-sm w-full mt-4"
            type="text"
            required
            placeholder="ادخل عنوان الرسالة"
            {...register("subject")}
          />
          <div className="my-4">
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
          </div>
          <div className="w-1/6 my-2">
            <PrimaryButton title="إرسال" onPress={handleSubmit} isLoading={emailSender.loading} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default EmailComponent;
