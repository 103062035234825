import React from "react";
import { useDispatch } from "react-redux";
import { deleteCoupon } from "../../store/home";

const Coupon = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-row bg-secondary justify-between w-full p-2 my-1 hover:bg-hover-color">
      <p className="w-2/4">{props.code}</p>
      <p className="w-1/4">{Math.floor(props.discount * 100)}%</p>
      <a
        onClick={(e) => {
          e.preventDefault();
          dispatch(deleteCoupon(props.id));
        }}
        href=""
        className="w-1/4 text-left"
      >
        <u className="text-red-700">حذف</u>
      </a>
    </div>
  );
};

export default Coupon;
