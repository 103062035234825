// Reducer
import {createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "./apiActions";

const slice = createSlice({
    name: "emailSender",
    initialState: {
        data: {},
        loading: false,
        lastFetch: null,
        isFailed: false,
        isSuccess: false,
        errorMessage: "",
        SuccessMessage: "",
    },
    reducers: {
        emailSendRequestStarted: (emailSender, action) => {
            emailSender.loading = true;
            emailSender.isSuccess = false;
            emailSender.isFailed = false;
        },
        emailSendRequestSuccess: (emailSender, action) => {
            emailSender.loading = false;
            emailSender.isSuccess = true;
            emailSender.isFailed = false;
            emailSender.SuccessMessage = "تم إرسال الإيميل بنجاح"
        },
        emailSendRequestFail: (emailSender, action) => {
            emailSender.loading = false;
            emailSender.isFailed = true;
            emailSender.isSuccess = false;
            emailSender.errorMessage = action.payload.detail
        },
        resetState: (emailSender, action) => {
            emailSender.data = {};
            emailSender.loading = false;
            emailSender.lastFetch = null;
            emailSender.isFailed = false;
            emailSender.isSuccess = false;
            emailSender.errorMessage = "";
            emailSender.SuccessMessage = "";
        }
    },
});

//Actions
const {
    emailSendRequestStarted,
    emailSendRequestSuccess,
    emailSendRequestFail,
    resetState,
} = slice.actions;

const sendEmail = (data) => (dispatch) => {
    dispatch(
        apiCallBegan({
        url: "v1/admin/students/email",
        data: {
            recipientEmail: data.recipientEmail !== "" ? data.recipientEmail : undefined,
            recipientGroup: data.recipientGroup !== "" ? data.recipientGroup : undefined,
            carbonCopyEmail: data.carbonCopyEmail !== "" ? data.carbonCopyEmail : undefined,
            carbonCopyGroup: data.carbonCopyGroup !== "" ? data.carbonCopyGroup : undefined,
            subject: data.subject !== "" ? data.subject : undefined,
            htmlMessage: data.htmlMessage !== "" ? data.htmlMessage : undefined,
        },
        onStart: emailSendRequestStarted.type,
        onSuccess: emailSendRequestSuccess.type,
        onError: emailSendRequestFail.type,
        method: "post"
    }));
}

const resetCurrentState = () => (dispatch) => {
    dispatch(resetState());
}

export default slice.reducer;
export {sendEmail, resetState}
