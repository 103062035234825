import { useState } from "react";
import PrimaryButton from "../buttons/primaryButton";
import { updateClass } from "../../store/classes";
import { useDispatch } from "react-redux";

const ClassCell = (props) => {
  const dispatch = useDispatch();
  const [classTitle, setClassTitle] = useState(props.title);
  const [classPrice, setClassPrice] = useState(props.price);
  const [classImg, setClassImg] = useState(props.img);

  const onSubmit = () => {
    dispatch(
      updateClass(
        {
          title: classTitle,
          description: "string",
          image: classImg,
          price: classPrice.toString(),
        },
        props.id
      )
    );
  };

  return (
    <div className="flex flex-row bg-secondary p-4 w-2/3 my-4 ">
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 ">
            <input
              className="bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full"
              type="text"
              value={classTitle}
              onChange={(v) => setClassTitle(v.target.value)}
              placeholder="عنوان الكلاس"
            />
            <div className="w-2/3">
              <PrimaryButton
                title="حفظ"
                isLoading={false}
                action={() => onSubmit()}
              />
            </div>
          </div>
          <div className="w-1/3 flex flex-col mx-2">
            <input
              className="bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full"
              type="text"
              value={classPrice}
              onChange={(v) => setClassPrice(v.target.value)}
              placeholder="SR"
            />
          </div>

          <div className="flex flex-col  w-1/3 ">
            <input
              className="bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full"
              type="text"
              value={classImg}
              onChange={(v) => setClassImg(v.target.value)}
              placeholder="رابط الصورة"
            />
            <img src={classImg} className="w-20 h-20 mx-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassCell;
