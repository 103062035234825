export function inputDecorator(isError) {
  if (isError) {
    return "bg-custom-g py-2 text-white rounded-sm me-2 border-2 border-red-600 my-2 focus:border-red-700 focus:ring-0 w-full placeholder-red-500";
  } else return "bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full";
}

export function checkboxDecorator(isError) {
  if (isError) {
    return "h-4 w-4 text-primary-blue-color  rounded border-2 border-red-600 my-2 focus:border-red-700 focus:ring-0 ";
  } else
    return "focus:ring-indigo-500 h-4 w-4 text-primary-blue-color border-gray-300 rounded";
}

export const selectedCell =
  "flex flex-row p-4  transition duration-300 ease-in-out bg-hover-color border-s-8 border-primary-blue-color my-2";
export const notSelectedCell =
  "flex flex-row p-4  transition duration-300 ease-in-out hover:bg-hover-color ";
