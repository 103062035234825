import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadCourses } from "../../store/resources";
import CourseCell from "./courseCell";
import LoadingIndicator from "../Loaders/loadingIndicator";

const VideosSectionComponent = () => {
  const dispatch = useDispatch();

  const courses = useSelector((state) => state.admin.resources);

  useEffect(() => {
    dispatch(loadCourses());
  }, []);

  let items = {};
  if (courses.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="flex flex-col mt-6 space-y-4">
        <p> الدروس الحالية</p>
        {Object.keys(courses.data).length > 0 ? (
          <div>
            {
              (items = Object.values(courses.data).map((v) => {
                return <CourseCell item={v} key={v.id} />;
              }))
            }
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
};

export default VideosSectionComponent;
