// resource.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "resources",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
    SuccessMessage: "",
  },
  reducers: {
    resourceRequested: (resource, action) => {
      resource.loading = true;
      resource.isSuccess = false;
    },
    resourceRequestFailed: (resource, action) => {
      resource.loading = false;
      resource.SuccessMessage = "";
      resource.isFailed = true;
      resource.isSuccess = false;
      resource.errorMessage = action.payload;
    },
    resourceLoadCoursesRequestSucceeded: (resource, action) => {
      resource.data = {};
      action.payload.forEach((item) => (resource.data[item.id] = item));
      resource.loading = false;
      resource.errorMessage = "";
      resource.isFailed = false;
      resource.isSuccess = true;
      resource.lastFetch = Date.now();
    },
    resourceChanged: (resource, action) => {
      resource.loading = false;
      resource.data[action.payload.id] = action.payload;
    },
    resourceRequestSucceeded: (resource, action) => {
      resource.loading = false;
      resource.data = action.payload;
    },
    resourceAddedSucceeded: (resource, action) => {
      resource.loading = false;
      resource.data.push(action.payload);
    },
    resourceUpdateSucceeded: (resource, action) => {
      resource.loading = false;
      window.location.reload();
    },
  },
});

// Actions
export const {
  resourceRequested,
  resourceRequestFailed,
  resourceLoadCoursesRequestSucceeded,
  resourceRequestSucceeded,
  resourceUpdateSucceeded,
  resourceChanged,
  resourceAddedSucceeded,
} = slice.actions;

export default slice.reducer;

const resourcesUrl = "/v1/admin/resources";

export const addResource = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: resourcesUrl,
      onStart: resourceRequested.type,
      onSuccess: resourceAddedSucceeded.type,
      onError: resourceRequestFailed.type,
      method: "post",
      data: data,
    })
  );
};

export const getResources = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: resourcesUrl,
      onStart: resourceRequested.type,
      onSuccess: resourceRequestSucceeded.type,
      onError: resourceRequestFailed.type,
      method: "get",
    })
  );
};

export const updateResource = (id, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: resourcesUrl + "/" + id,
      onStart: resourceRequested.type,
      onSuccess: resourceUpdateSucceeded.type,
      onError: resourceRequestFailed.type,
      method: "patch",
      data,
    })
  );
};

const coursesUrl = "/v1/admin/resources/courses";

export const loadCourses = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: coursesUrl,
      onStart: resourceRequested.type,
      onSuccess: resourceLoadCoursesRequestSucceeded.type,
      onError: resourceRequestFailed.type,
      method: "get",
    })
  );
};

const videoUrl = "/v1/admin/resources/courses";

export const addVideo = (id, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: videoUrl + "/" + id,
      onStart: resourceRequested.type,
      onSuccess: resourceChanged.type,
      onError: resourceRequestFailed.type,
      data: data,
      method: "post",
    })
  );
};

export const updateVideo = (id, vId, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: videoUrl + "/" + id + "/" + vId,
      onStart: resourceRequested.type,
      onSuccess: resourceChanged.type,
      onError: resourceRequestFailed.type,
      data: data,
      method: "Patch",
    })
  );
};
