const LoadingIndicator = () => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className=" flex justify-center items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-white"></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
