import React from "react";

const Dropdown = ({ list, addItem, selectedItems }) => {
  return (
    <div
      id="dropdown"
      className="absolute shadow  bg-secondary  w-auto  rounded h-64 overflow-y-scroll z-50 "
    >
      <div className="flex flex-col w-full">
        {list.map((item, key) => {
          return (
            <div
              key={key}
              className="cursor-pointer w-full border-hover-color rounded-t border-b "
              onClick={() => addItem(item)}
            >
              <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:bg-hover-color">
                <div className="w-full items-center flex">
                  <div className="mx-2 leading-6  ">{item.title}</div>
                  {selectedItems.includes(item) ? (
                    <i className="fa fa-check" aria-hidden="true"></i>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
