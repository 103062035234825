import { combineReducers } from "redux";
import auth from "./auth";
import classes from "./classes";
import home from "./home";
import members from "./members";
import resources from "./resources";
import emailSender from "./emailSender";

export default combineReducers({
  currentAdmin: auth,
  classes: classes,
  resources: resources,
  members: members,
  home: home,
  emailSender: emailSender
});
