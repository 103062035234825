import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addClass, getClasses } from "../../store/classes";
import { useForm } from "react-hook-form";
import { inputDecorator } from "../../utils/styleHelper";

import PrimaryButton from "../buttons/primaryButton";
import LoadingIndicator from "../Loaders/loadingIndicator";
import ClassCell from "./classCell";
import ErrorAlert from "../Loaders/errorAlert";
const ClassesComponent = () => {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.admin.classes);
  let classesList = [];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    dispatch(addClass(data));
  };

  useEffect(() => {
    dispatch(getClasses());
  }, []);

  if (classes.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="flex flex-col mt-6 space-y-4 align-middle">
        {classes.SuccessMessage != "" ? (
          <div className="my-2">
            {" "}
            <ErrorAlert message={classes.errorMessage} />
          </div>
        ) : (
          <div></div>
        )}
        <p>إضافة كلاس:</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row bg-secondary p-4 w-2/3 mb-8">
            <div className="flex flex-col">
              <div className="flex flex-row ">
                <input
                  className={
                    typeof errors.title == "undefined"
                      ? inputDecorator(false)
                      : inputDecorator(true)
                  }
                  type="text"
                  placeholder="عنوان الكلاس"
                  {...register("title", { required: true })}
                />
                <input
                  className={
                    typeof errors.price == "undefined"
                      ? inputDecorator(false)
                      : inputDecorator(true)
                  }
                  type="text"
                  placeholder="SR"
                  {...register("price", { required: true })}
                />
                <input
                  className={
                    typeof errors.image == "undefined"
                      ? inputDecorator(false)
                      : inputDecorator(true)
                  }
                  type="text"
                  placeholder="رابط الصورة"
                  {...register("image", { required: true })}
                />
              </div>
              <div className="w-1/4">
                <PrimaryButton title="إضافة" isLoading={false} />
              </div>
            </div>
          </div>
        </form>
        {classes.data.length > 0 ? (
          <div>
            <p>الكلاسات الحالية:</p>

            {
              (classesList = classes.data.map((value) => {
                return (
                  <ClassCell
                    key={value.id}
                    title={value.title}
                    img={value.image}
                    price={value.price}
                    id={value.id}
                  />
                );
              }))
            }
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
};

export default ClassesComponent;

/**
 *
 */
