// home.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "home",
  initialState: {
    data: {},
    coupons: [],
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
    errorMessageCoupon: "",
    SuccessMessage: "",
    SuccessMessageConfig: "",
  },
  reducers: {
    homeRequested: (home, action) => {
      home.errorMessage = "";
      home.SuccessMessage = "";
      home.loading = true;
      home.isSuccess = false;
    },
    homeRequestFailed: (home, action) => {
      home.loading = false;
      home.SuccessMessage = "";
      home.isFailed = true;
      home.isSuccess = false;
      home.errorMessage = action.payload;
    },
    homeRequestSucceeded: (home, action) => {
      home.data = action.payload;
      home.loading = false;
      home.errorMessage = "";
      home.isFailed = false;
      home.isSuccess = true;
      home.lastFetch = Date.now();
    },
    setHomeConfigRequestSucceeded: (home, action) => {
      home.data = action.payload;
      home.loading = false;
      window.location.reload();
      home.isFailed = false;
      home.isSuccess = true;
      home.lastFetch = Date.now();
    },
    defaultClassSet: (home, action) => {
      home.loading = false;
      home.SuccessMessage = "تم الدعس وتحديث الكلاس الحالي بنجاح!";
    },
    getCouponSucceeded: (home, action) => {
      home.loading = false;
      home.coupons = action.payload;
    },
    addCouponSucceeded: (home, action) => {
      home.loading = false;
      home.coupons.push(action.payload);
    },
    couponFailed: (home, action) => {
      home.loading = false;
      home.SuccessMessage = "";
      home.isFailed = true;
      home.isSuccess = false;
      home.errorMessageCoupon = action.payload;
    },
    deleteCouponSucceeded: (home, action) => {
      home.loading = false;
      home.coupons = home.coupons.filter((v) => v.id !== action.payload.id);
    },
  },
});

// Actions
export const {
  homeRequested,
  homeRequestFailed,
  homeRequestSucceeded,
  getCouponSucceeded,
  defaultClassSet,
  setHomeConfigRequestSucceeded,
  addCouponSucceeded,
  couponFailed,
  deleteCouponSucceeded,
} = slice.actions;

export default slice.reducer;

const homeConfigUrl = "/v1/landing/config";

export const getHomeConfig = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: homeConfigUrl,
      onStart: homeRequested.type,
      onSuccess: homeRequestSucceeded.type,
      onError: homeRequestFailed.type,
      method: "get",
    })
  );
};

const updateHomeConfig = "/v1/admin/landing/config";
export const setHomeConfig = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: updateHomeConfig,
      onStart: homeRequested.type,
      onSuccess: setHomeConfigRequestSucceeded.type,
      onError: homeRequestFailed.type,
      method: "patch",
      data,
    })
  );
};

const defaultClassUrl = "/v1/admin/carts/default";

export const setDefaultClass = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      data: id,
      url: defaultClassUrl,
      onStart: homeRequested.type,
      onSuccess: defaultClassSet.type,
      onError: homeRequestFailed.type,
      method: "post",
    })
  );
};

const getCouponUrl = "/v1/admin/carts/coupons";

export const getCoupon = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: getCouponUrl,
      onStart: homeRequested.type,
      onSuccess: getCouponSucceeded.type,
      onError: couponFailed.type,
      method: "get",
    })
  );
};

export const addCoupon = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      data,
      url: getCouponUrl,
      onStart: homeRequested.type,
      onSuccess: addCouponSucceeded.type,
      onError: couponFailed.type,
      method: "post",
    })
  );
};

export const deleteCoupon = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: getCouponUrl + "/" + id,
      onStart: homeRequested.type,
      onSuccess: deleteCouponSucceeded.type,
      onError: couponFailed.type,
      method: "delete",
    })
  );
};
