import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "./reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import api from "./middleware/api";
import fileDownloader from "./middleware/fileDownload";

export const history = createBrowserHistory();

export default function () {
  return configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(routerMiddleware(history))
        .concat(api)
        .concat(fileDownloader),
  });
}
