import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addMember } from "../../../store/members";
import { inputDecorator } from "../../../utils/styleHelper";
import PrimaryButton from "../../buttons/primaryButton";
import ErrorAlert from "../../Loaders/errorAlert";
import CountryCode from "./countrycode";
import CountryListComponent from "./countryList";

const AddMember = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(addMember(data));
  };

  const members = useSelector((state) => state.admin.members);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center my-12">
        <p className="text-white text-xl">إنشاء الحساب</p>
        <div className="flex flex-col bg-secondary py-8  md:px-0 px-4 mt-6 text-center mx-6 rounded-md items-center ">
          <p className="text-white text-sm">
            سيتم إرسال ايميل يحتوي على كلمة المرور للعضو المسجل*
          </p>
          {typeof members.errorMessage.title == "undefined" ? (
            <div className="bg-secondary"></div>
          ) : (
            <div className="w-2/3">
              <ErrorAlert message={members.errorMessage.title} />
            </div>
          )}
          <div className="flex flex-row  w-full md:w-2/3 md:px-0 px-4 mt-4">
            <div className="flex flex-col w-1/2 me-2">
              <input
                className={
                  typeof errors.firstName == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="الإسم الأول*"
                {...register("firstName", { required: true })}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <input
                className={
                  typeof errors.lastName == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="الإسم الأخير*"
                {...register("lastName", { required: true })}
              />
            </div>
          </div>
          <div className="w-full md:w-2/3 md:px-0 px-4">
            <input
              className={
                typeof errors.email == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              type="email"
              placeholder="البريد الإلكتروني*"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+\.\S+$/,
              })}
            />
          </div>
          <div className="w-full md:w-2/3 md:px-0 px-4">
            <CountryListComponent
              className="mb-4 "
              label="country"
              register={register}
              required
            />
          </div>

          <div className="w-full md:w-2/3 md:px-0 px-4">
            <input
              className={
                typeof errors.city == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              type="text"
              placeholder="المدينة*"
              {...register("city", { required: true })}
            />
          </div>

          <div className="flex flex-row w-full md:w-2/3 md:px-0 px-4">
            <div className="w-2/3">
              <input
                className={
                  typeof errors.phoneNumber == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="tel"
                placeholder="رقم الجوال*"
                {...register("phoneNumber", {
                  required: true,
                })}
              />
            </div>

            <div className="flex flex-col w-1/3 ">
              <CountryCode
                label="countryCode"
                register={register}
                required
                className="appearance-none"
              />
            </div>
          </div>
          <div className="w-full md:w-2/3 md:px-0 px-4  my-2">
            <input
              className="bg-custom-g py-2  text-white rounded-sm w-full  border-custom-g"
              type="text"
              placeholder="حساب (Instagram - twitter - website)"
              {...register("account")}
            />
          </div>

          <div className=" mb-6 w-full md:w-2/3 md:px-0 px-4">
            <PrimaryButton title="إنشاء حساب" isLoading={members.loading} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddMember;
