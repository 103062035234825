import { Link } from "react-router-dom";

const MemberListCell = (props) => {
  return (
    <Link to={"/admin/membersList/" + props.title}>
      <button
        type="button"
        className="my-2 transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  transform hover:-translate-y-1 hover:scale-110 border-solid border-2 rounded text-white px-2 py-1 w-full"
      >
        {props.title}
      </button>
    </Link>
  );
};

export default MemberListCell;
