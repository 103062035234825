import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getClasses } from "../../../store/classes";
import { getMemberDetails, updateMemberDetails } from "../../../store/members";
import ErrorAlert from "../../Loaders/errorAlert";
import LoadingIndicator from "../../Loaders/loadingIndicator";
import PopupDialog from "../../popup";
import MultiSelect from "../../selection/multiSelection";
import PrimaryButton from "../../buttons/primaryButton";

const MemberDetailsComponent = () => {
  const dispatch = useDispatch();
  const members = useSelector((state) => state.admin.members);
  const [selectedItems, setSelected] = useState([]);
  const [isShown, setShow] = useState(false);
  const classes = useSelector((state) => state.admin.classes.data);
  const { key, mId } = useParams();

  useEffect(() => {
    dispatch(getMemberDetails(mId));
    dispatch(getClasses());
  }, []);

  const getSelected = (items) => {
    console.log(items);
    setSelected(items);
  };

  const toggle = () => {
    setShow(!isShown);
  };

  if (members.loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div>
        {isShown ? <PopupDialog toggle={toggle} /> : <div></div>}
        {typeof members.errorMessage.title !== "undefined" ? (
          <ErrorAlert message={members.errorMessage.title} />
        ) : (
          <div>
            {typeof members.memberDetailsData.fullName !== "undefined" ? (
              <div className="flex flex-col space-y-4 mt-6 ">
                <div className="flex flex-col bg-secondary p-4 space-y-4">
                  <div className="flex flex-row">
                    <p className="flex-none w-32">الاإسم:</p>
                    <p className="flex-grow ms-4">
                      {members.memberDetailsData.fullName}
                    </p>
                    <a
                      href=""
                      className="text-red-700 flex-none"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(true);
                      }}
                    >
                      <u>حذف الحساب</u>
                    </a>
                  </div>
                  <div className="flex flex-row">
                    <p className="flex-none w-32">البريد الإلكتروني:</p>
                    <p className="flex-grow ms-4">
                      {members.memberDetailsData.email}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="flex-none w-32">الدولة:</p>
                    <p className="flex-grow ms-4">
                      {members.memberDetailsData.country}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="flex-none w-32">المدينة:</p>
                    <p className="flex-grow ms-4">
                      {members.memberDetailsData.city}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="flex-none w-32">رقم الجوال:</p>
                    <p className="flex-grow ms-4">
                      {members.memberDetailsData.phoneNumber}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="flex-none w-32">Social Media:</p>
                    <p className="flex-grow ms-4">
                      {" "}
                      {members.memberDetailsData.socialMediaAccounts}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row bg-secondary p-4">
                  <p className="flex-none w-32">الكلاسات المسجلة</p>
                  <div className="flex-grow flex-wrap ">مثال 1</div>
                  <div className=" flex flex-row flex-grow items-center">
                    {classes.length > 0 ? (
                      (console.log(classes.length),
                      (
                        <MultiSelect
                          getSelectedItems={getSelected}
                          items={classes}
                          selected={members.memberDetailsData.classes}
                        />
                      ))
                    ) : (
                      <div></div>
                    )}
                    <div className="w-1/3 mx-4">
                      <PrimaryButton
                        title="حفظ"
                        loading={members.loading}
                        action={(e) => {
                          e.preventDefault();
                          dispatch(
                            updateMemberDetails(mId, {
                              classIds: selectedItems.map((v) => v.id),
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {members.memberDetailsData.courses.map((v, i) => {
                    return (
                      <div
                        className="flex flex-col bg-secondary text-center p-4 space-y-2"
                        key={i}
                      >
                        <p className="text-2xl">{v.title}</p>
                        <p className="text-xl">
                          {"تمت مشاهدة " +
                            v.totalLessonsFinishedByStudent +
                            " من " +
                            v.totalLessons +
                            " مقطع "}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
    );
  }
};

export default MemberDetailsComponent;
