import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, set } from "react-hook-form";
import { inputDecorator } from "../../utils/styleHelper";
import PrimaryButton from "../buttons/primaryButton";
import MultiSelect from "../selection/multiSelection";
import ErrorAlert from "../Loaders/errorAlert";
import { updateResource } from "../../store/resources";
import { useSelector } from "react-redux";

const ResourceCell = (props) => {
  const dispatch = useDispatch();
  const maxLength = 250;
  const [counter, setCounter] = useState(props.item.description.length);
  const [title, setTitle] = useState(props.item.title);
  const [url, setUrl] = useState(props.item.url);
  const [image, setImage] = useState(props.item.image);
  const [description, setDescription] = useState(props.item.description);
  const [error, setError] = useState("");
  const [selectedClasses, setSelectedClasses] = useState(props.item.classes);
  const classes = props.classesList;
  const resources = useSelector((state) => state.admin.resources);

  const getSelected = (items) => {
    setSelectedClasses(items);
  };

  const onSubmit = () => {
    console.log(title);
    console.log(url);
    console.log(description);
    console.log(image);

    if (title == "" || image == "" || description == "")
      setError("لازم كل الحقول تكون مليانة");
    else {
      dispatch(
        updateResource(props.item.id, {
          title: title,
          description: description,
          imageUrl: image,
          url: url === null ? null : url,
          classIds: selectedClasses.map((v) => v.id),
        })
      );
    }
  };
  return (
    <div className="flex flex-row bg-secondary p-4 w-4/5 mb-8 ">
      <div className="flex flex-col w-2/3 ">
        <input
          className="bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full"
          value={title}
          onChange={(v) => setTitle(v.target.value)}
          type="text"
          placeholder="عنوان المصدر"
        />
        <textarea
          placeholder="نبذة عامة"
          className="bg-custom-g my-4"
          maxLength={maxLength}
          onBeforeInput={(v) => setCounter(v.target.value.length)}
          value={description}
          onChange={(v) => {
            setCounter(v.target.value.length);
            setDescription(v.target.value);
          }}
        />
        <div>
          ({counter}/{maxLength})
        </div>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="w-1/3">
            {" "}
            <PrimaryButton
              title="تحديث"
              isLoading={resources.loading}
              action={onSubmit}
            />
          </div>
          <div className="w-2/4">
            <input
              onChange={(v) => setUrl(v.target.value)}
              value={url === null ? "" : url}
              className="bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full"
              type="text"
              placeholder="رابط URL خارجي  "
            />
          </div>
        </div>
        <div className="w-3/6">
          {error == "" ? <div></div> : <ErrorAlert message={error} />}
        </div>
      </div>
      <div className="flex flex-col w-1/3 mx-2">
        <input
          className="bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full"
          type="text"
          value={image}
          onInput={(v) => {
            setImage(v.target.value);
          }}
          placeholder="رابط الصورة"
        />

        {url == "" ? (
          <div className="w-32 h-24 mb-1"></div>
        ) : (
          <img src={image} className="w-32 h-20 mx-2 mt-2 mb-3" />
        )}
        <MultiSelect
          items={classes}
          getSelectedItems={getSelected}
          selected={selectedClasses}
        />
      </div>
    </div>
  );
};

export default ResourceCell;
