// Auth.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "auth",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
    SuccessMessage: "",
  },
  reducers: {
    authRequested: (currentUser, action) => {
      currentUser.loading = true;
      currentUser.isSuccess = false;
    },
    authRequestFailed: (currentUser, action) => {
      currentUser.loading = false;
      currentUser.SuccessMessage = "";
      currentUser.isFailed = true;
      currentUser.isSuccess = false;
      currentUser.errorMessage = action.payload;
    },
    authSuccess: (currentUser, action) => {
      currentUser.data = action.payload;
      localStorage.setItem("token", action.payload.refreshToken);
      localStorage.setItem("isAuth", true);
      currentUser.loading = false;
      currentUser.errorMessage = "";
      currentUser.isFailed = false;
      currentUser.isSuccess = true;
      currentUser.lastFetch = Date.now();
    },
    logout: (currentUser, action) => {
      currentUser.data = null;
      localStorage.removeItem("token");
      localStorage.setItem("isAuth", false);
    },
    refreshToken: (currentUser, action) => {
      currentUser.data = action.payload;
    },
  },
});

// Actions
export const {
  authRequested,
  authRequestFailed,
  authSuccess,
  logout,
  refreshToken,
} = slice.actions;

export default slice.reducer;

const loginUrl = "/v1/admin/login";

export const login = (userData) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: loginUrl,
      data: userData,
      onStart: authRequested.type,
      onSuccess: authSuccess.type,
      onError: authRequestFailed.type,
      method: "post",
      path: "/admin/home",
    })
  );
};

const refreshTokenUrl = "/v1/admin/refresh-token";

export const refreshTokenAction = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: refreshTokenUrl,
      data: localStorage.getItem("token"),
      onStart: authRequested.type,
      onSuccess: authSuccess.type,
      onError: authRequestFailed.type,
      method: "get",
    })
  );
};
