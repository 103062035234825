import React from "react";
import ErrorAlert from "../Loaders/errorAlert";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth";
import PrimaryButton from "../buttons/primaryButton";
import { inputDecorator } from "../../utils/styleHelper";

const LoginBox = () => {
  const auth = useSelector((state) => state.admin.currentAdmin);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    dispatch(login(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col bg-secondary py-8  md:px-20 px-8 mt-6 text-center mx-4 rounded-md items-center">
        <div>
          <a href="/home" className="flex">
            <img className="p-4 w-28 h-24" src="assets/images/hrrir.png" />
          </a>
        </div>
        <p className="text-white mb-8">سجل دخولك !</p>
        {typeof auth.errorMessage.title == "undefined" ? (
          <div className="bg-secondary"></div>
        ) : (
          <ErrorAlert message={auth.errorMessage.title} />
        )}
        <div className="w-full">
          <input
            className={
              typeof errors.userName == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="text"
            placeholder="اسم المستخدم"
            {...register("userName", { required: true })}
          />
        </div>
        <div className="w-full">
          <input
            className={
              typeof errors.password == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="password"
            placeholder="كلمة المرور"
            {...register("password", { required: true })}
          />
        </div>

        <div className="my-4 w-full">
          <PrimaryButton title="  تسجيل الدخول" isLoading={auth.loading} />
        </div>
      </div>
    </form>
  );
};

export default LoginBox;
